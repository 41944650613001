<script>

import GerkonCard_3ValuesList from "../gerkon/GerkonCard_3ValuesList";

export default {
    name: "LeakCard_3ValuesList",
    extends: GerkonCard_3ValuesList,
    data() {
        return {
            textStatus1: this.$t('Обнаружено'),
            textStatus0: this.$t('Не обнаружено'),
            classStatus1: "fa-water text-danger",
            classStatus0: "fa-water text-muted",
        }
    },
}
</script>
