<script>

import GerkonCard_1Table from "../gerkon/GerkonCard_1Table";

export default {
    name: "LeakCard_1Table",
    extends: GerkonCard_1Table,
    data() {
        return {
            textStatus1: this.$t('Обнаружено:'),
            textStatus0: this.$t('Не обнаружено:'),
            classStatus1: null,
            classStatus0: null,
        }
    },
}
</script>
