<script>

import Icon from "../../Icon";
import DefaultCard_1Header from "../default/DefaultCard_1Header";
import GerkonCard_1Table from "./LeakCard_1Table";
import GerkonCard_2 from "../gerkon/GerkonCard_2";
import LeakCard_2LatestValue from "./LeakCard_2LatestValue";
import LeakCard_2MetricsButtons from "./LeakCard_2MetricsButtons";

export default {
    name: "LeakCard_2",
    extends: GerkonCard_2,
    components: {
        HeaderComponent: DefaultCard_1Header,
        MetricsButtonsComponent: LeakCard_2MetricsButtons,
        LatestValueComponent: LeakCard_2LatestValue,
        ChartComponent: GerkonCard_1Table,
        Icon
    }
}
</script>
