<script>

import Icon from "../../Icon";
import DefaultCard_1Header from "../default/DefaultCard_1Header";
import LeakCard_3ValuesList from "./LeakCard_3ValuesList";
import GerkonCard_3 from "../gerkon/GerkonCard_3";
import LeakCard_1Table from "./LeakCard_1Table";

export default {
    name: "LeakCard_3",
    extends: GerkonCard_3,
    components: {
        HeaderComponent: DefaultCard_1Header,
        ValuesListComponent: LeakCard_3ValuesList,
        ChartComponent: LeakCard_1Table,
        Icon
    },
}
</script>
