<template>
    <div ref="latestValue" class="latest-value animate__animated">
        <div class="latest-value__bg" :style="deviceImage">
            <div v-if="value !== null">
                <div v-if="currentMetric.getSlug() === 'status'"
                     class="d-flex justify-content-center align-content-center text-center">
                    <div>
                        <div class="d-flex justify-content-center align-items-center font-weight-bold mt-1 text-uppercase text-white">
                            {{ status ? $t('Обнаружено') : $t('Не обнаружено') }}
                        </div>
                    </div>
                </div>
                <div v-else-if="currentMetric.getSlug() === 'times'">
                    <div class="latest-value__value">{{ Math.round(value) }}</div>
                </div>
                <div v-else>
                    <div class="latest-value__value">{{ Math.round(value) }}</div>
                    <div class="latest-value__units">{{ units }}</div>
                </div>
            </div>
            <div v-else>
                <div class="latest-value__value">N/A</div>
                <div class="latest-value__units">{{ units }}</div>
            </div>
        </div>
    </div>
</template>

<script>

import GerkonCard_1LatestValue from "../gerkon/GerkonCard_1LatestValue";

export default {
    name: "LeakCard_1LatestValue",
    extends: GerkonCard_1LatestValue,
}
</script>
