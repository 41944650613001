<script>

import GerkonCard_2MetricsButtons from "../gerkon/GerkonCard_2MetricsButtons";

export default {
    name: "LeakCard_2MetricsButtons",
    extends: GerkonCard_2MetricsButtons,
    data() {
        return {
            textStatus1: this.$t('Обнаружено'),
            textStatus0: this.$t('Не обнаружено'),
        }
    },
}
</script>
