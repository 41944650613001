<script>

import LeakCard_1 from "./LeakCard_1";
import LeakCard_2 from "./LeakCard_2";
import LeakCard_3 from "./LeakCard_3";
import AbstractDefaultCard from "../default/AbstractDefaultCard";

export default {
    name: "AbstractLeakCard",
    extends: AbstractDefaultCard,
    components: {
        LeakCard_1,
        LeakCard_2,
        LeakCard_3,
    },
    computed: {
        currentCardComponent() {
            switch (this.currentCardDesign) {
                case 3:
                    return LeakCard_3;
                case 2:
                    return LeakCard_2;
                default:
                    return LeakCard_1
            }
        }
    },
}
</script>
